import * as React from "react";
import software from "../../Image/software_engineer.svg";
import { TypeAnimation } from "react-type-animation";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./style.css";

class Profile extends React.Component {
  render() {
    return (
      <div id="profile" className="profil">
        <Container>
          <Row xs={1} md={2}>
            <Col lg={7}>
              <h3 className="mb-3">
                I am{" "}
                <TypeAnimation
                  sequence={[
                    "SARA SENNOUNI",
                    1500,
                    "FULL STACK DEVELOPER",
                    1500,
                  ]}
                  speed={40} // Custom Speed from 1-99 - Default Speed: 40
                  style={{ color: "#007bff", textShadow: "2px 2px #bebebe" }}
                  wrapper="span" // Animation will be rendered as a <span>
                  repeat={Infinity} // Repeat this Animation Sequence infinitely
                />
              </h3>
              <p className="lead  mt-4">
                I am a responsible, and hard-working IT person. Who worked for
                different projects and that helped me to adapt to changes
                quickly and made me a mature team worker. I can work well both
                in a team environment as well as using my initiative. I can work
                under pressure and adhere to strict deadlines.
              </p>
              <a href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link">
                <Button size="lg" className="resume">
                  View Resume
                </Button>
              </a>
            </Col>
            <Col lg={5}>
              <img alt="software engineer" src={software} width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Profile;
