import * as React from "react";
import { Container, Image, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { skillsData } from "../../data/Skills";
const Skills = () => {
  return (
    <div id="skills" className="skills pad blocs education">
      <Container>
        <div className="text-center mb-5">
          <h2 className="display-4 mb-3">Skills</h2>
          <p className="lead text-muted">
            Showcasing my commitment to continuous learning and professional
            development
          </p>
        </div>
        <Row>
          {skillsData.map((category, index) => (
            <Col key={index} lg={4} md={6}>
              <Image src={category.imageSrc} style={{ paddingBlock: "20px" }} />
              <h6>{category.category}</h6>
              <p>{category.technologies.join(", ")}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
export default Skills;
