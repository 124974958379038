import React, { useState } from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import geofit from "../../Image/ERP.svg";
import { certificates } from "../../data/CertificatesData";

import "./../Screens/style.css";

const Certificates = () => {
  const [visibleItems, setVisibleItems] = useState(3);

  const handleShowMore = () => {
    setVisibleItems((prev) => prev + 3);
  };

  const handleShowLess = () => {
    setVisibleItems(3);
    // Smooth scroll to the top of the certificates section
    document
      .getElementById("experience")
      .scrollIntoView({ behavior: "smooth" });
  };

  const visibleCertificates = certificates.slice(0, visibleItems);
  const isShowingAll = visibleItems >= certificates.length;
  const isShowingMore = visibleItems > 3;

  return (
    <section id="experience" className="py-5 pad">
      <Container>
        <div className="text-center mb-5">
          <h2 className="display-4 mb-3">Professional Certifications</h2>
          <p className="lead text-muted">
            Showcasing my commitment to continuous learning and professional
            development
          </p>
        </div>

        <Row className="g-4 pad">
          {visibleCertificates.map((cert) => (
            <Col key={cert.id} xs={12} md={6} lg={4}>
              <Card className="h-100 shadow-sm certificate-card">
                <div className={`bg-${cert.bgColor} bg-opacity-10 p-3`}>
                  <Card.Img
                    variant="top"
                    src={cert.image}
                    alt={cert.title}
                    className="rounded certificate-image"
                  />
                </div>

                <Card.Body>
                  <Card.Title className="h5 mb-3">{cert.title}</Card.Title>
                  <div className="mb-2 text-muted small">
                    <div className="mb-1">{cert.issuer}</div>
                    <div>{cert.date}</div>
                  </div>

                  <div className="mt-3">
                    {cert.skills.map((skill, index) => (
                      <Badge
                        key={index}
                        bg={cert.bgColor}
                        className="me-2 mb-2"
                      >
                        {skill}
                      </Badge>
                    ))}
                  </div>
                </Card.Body>

                <Card.Footer className="bg-transparent border-0 text-center">
                  <button
                    className={`btn btn-outline-${cert.bgColor} btn-sm`}
                    onClick={() => window.open(cert.url, "_blank")}
                  >
                    View Certificate
                  </button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Show More/Less Buttons */}
        <div className="text-center mt-4">
          {!isShowingAll && (
            <button
              className="btn btn-outline-primary me-2"
              onClick={handleShowMore}
            >
              Show More
            </button>
          )}
          {isShowingMore && (
            <button
              className="btn btn-outline-secondary"
              onClick={handleShowLess}
            >
              Show Less
            </button>
          )}
        </div>
      </Container>

      <style jsx>{``}</style>
    </section>
  );
};

export default Certificates;
