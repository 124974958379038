import dynamics from "../Image/Dynamics.PNG";
import geofit from "../Image/geofit.png";
import pfe from "../Image/pfe.png";
import smartfocus from "../Image/smartfocus.PNG";

export const projects = [
  {
    id: 1,
    title: "Smart Focus",
    description:
      "A full-featured e-commerce platform with admin dashboard, user authentication, and payment integration.",
    image: smartfocus, // Replace with your image path
    technologies: ["React", "Node.js", "MongoDB", "Redux"],
    liveDemo: "#",
    sourceCode: "#",
    category: "Full Stack",
  },
  {
    id: 2,
    title: "University project management",
    description:
      "Real-time social media platform with chat, post sharing, and user profiles.",
    image: pfe, // Replace with your image path
    technologies: ["React", "Firebase", "Material-UI", "Socket.io"],
    liveDemo: "#",
    sourceCode: "#",
    category: "Full Stack",
  },
  {
    id: 3,
    title: "Development of a Transport Management System using Dynamics 365",
    description:
      "Interactive weather app showing forecasts and weather maps for multiple cities.",
    image: dynamics, // Replace with your image path
    technologies: ["JavaScript", "Weather API", "Bootstrap"],
    liveDemo: "#",
    sourceCode: "#",
    category: "Frontend",
  },
  {
    id: 4,
    title:
      "Development of a decision information system for Pexiluis Africa management.",
    description:
      "Interactive weather app showing forecasts and weather maps for multiple cities.",
    image: geofit, // Replace with your image path
    technologies: ["JavaScript", "Weather API", "Bootstrap"],
    liveDemo: "#",
    sourceCode: "#",
    category: "Frontend",
  },
];
