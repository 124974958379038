import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { Phone, Mail, MapPin } from "lucide-react";
import emailjs from '@emailjs/browser';
import "./../Screens/style.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    nom: "",
    email: "",
    sujet: "",
    message: "",
  });

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const serviceId = 'service_0xxfx1l';
  const templateId = 'template_fwouojc';
  const publicKey = 't6dQ0YRTm0VZYxtvS';

  // Object of dynamics template params
  const templateParams = {
    from_name: formData.nom,
    from_email: formData.email,
    subject : formData.sujet,
    message: formData.message
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setValidated(true);  // Show validation state
  
    if (form.checkValidity() === false) {
      event.stopPropagation();
      return; // Stop here if invalid
    }
  
    // Only proceed if form is valid
    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then(() => {
        setValidated(false); // Reset validation state
        setFormData({        // Reset form
          nom: "",
          email: "",
          sujet: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error('Failed:', error);
      });
  };

  const ContactInfo = () => (
    <div className="contact-info">
      <div className="contact-card">
        <div className="icon-wrapper">
          <Phone className="contact-icon" size={24} />
        </div>
        <div className="contact-content">
          <h3>Contact on phone</h3>
          <p>+212-698-662949</p>
        </div>
      </div>

      <div className="contact-card">
        <div className="icon-wrapper">
          <Mail className="contact-icon" size={24} />
        </div>
        <div className="contact-content">
          <h3>Contact on mail</h3>
          <p> sarasennouni@outlook.com</p>
          <p> sara.sennouni1214@gmail.com</p>
        </div>
      </div>

      <div className="contact-card">
        <div className="icon-wrapper">
          <MapPin className="contact-icon" size={24} />
        </div>
        <div className="contact-content">
          <h3>Contact address</h3>
          <p>Boulevard Laymoune - Morocco, Casablanca</p>
        </div>
      </div>
    </div>
  );

  return (
    <Container className="py-5">
      <div className="text-center mb-5">
        <h2 className="display-4 mb-3">Contact Information</h2>
        <p className="lead text-muted">
          Showcasing my commitment to continuous learning and professional
          development
        </p>
      </div>

      <Row className="g-4 text-start">
        <Col lg={7}>
          <Card>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Nom *</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez entrer votre nom
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez entrer une adresse email valide
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Sujet</Form.Label>
                  <Form.Control
                    type="text"
                    name="sujet"
                    value={formData.sujet}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Message *</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows={5}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez entrer votre message
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-grid">
                  <Button variant="primary" type="submit" size="lg">
                    Envoyer
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={5}>
          <ContactInfo />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
