import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { projects } from "../../data/Projects";
import "./../Screens/style.css";

const Project = () => {
  const categories = ["All", "Full Stack", "Frontend", "Backend"];
  const [activeCategory, setActiveCategory] = useState("All");
  const [visibleProjects, setVisibleProjects] = useState(3);

  // Filter projects based on category
  const filteredProjects =
    activeCategory === "All"
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  // Get currently visible projects
  const currentlyVisible = filteredProjects.slice(0, visibleProjects);

  // Handle Show More click
  const handleShowMore = () => {
    setVisibleProjects((prev) => prev + 3);
  };

  // Reset visible projects when category changes
  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setVisibleProjects(3);
  };

  return (
    <div id="project" className="pad blocs education">
      <Container>
        {/* Header Section */}
        <div className="text-center mb-5">
          <h2 className="display-4 mb-3">My Projects</h2>
          <p className="lead text-muted">
            Explore some of my recent work and personal projects
          </p>
        </div>

        {/* Category Filters */}
        <div className="text-center mb-4">
          {categories.map((category) => (
            <Button
              key={category}
              variant={
                activeCategory === category ? "primary" : "outline-primary"
              }
              className="mx-2 mb-2"
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </Button>
          ))}
        </div>

        {/* Projects Grid */}
        <Row xs={1} md={2} lg={3} className="g-4">
          {currentlyVisible.map((project) => (
            <Col key={project.id}>
              <Card className="h-100 project-card">
                <div className="project-image-wrapper">
                  <Card.Img
                    variant="top"
                    src={project.image}
                    alt={project.title}
                    className="project-image"
                  />
                  <div className="project-overlay">
                    <Button
                      variant="light"
                      size="sm"
                      href={project.liveDemo}
                      target="_blank"
                      className="me-2"
                    >
                      Live Demo
                    </Button>
                    <Button
                      variant="light"
                      size="sm"
                      href={project.sourceCode}
                      target="_blank"
                    >
                      Source Code
                    </Button>
                  </div>
                </div>

                <Card.Body>
                  <Card.Title>{project.title}</Card.Title>
                  <Card.Text className="text-muted">
                    {project.description}
                  </Card.Text>
                  <div className="mt-3">
                    {project.technologies.map((tech, index) => (
                      <Badge key={index} bg="primary" className="me-2 mb-2">
                        {tech}
                      </Badge>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        {/* Show More Button */}
        {visibleProjects < filteredProjects.length && (
          <div className="text-center mt-5">
            <Button
              variant="outline-primary"
              size="lg"
              onClick={handleShowMore}
              className="px-4"
            >
              Show More Projects
            </Button>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Project;
