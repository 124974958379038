import geofit from "../Image/geofit.png";
import docker from "../Image/Certificat/docker.png";
import OWASP from "../Image/Certificat/OWASP.jpg";

export const certificates = [
  {
    id: 1,
    title: "The Absolute Beginner's Guide to Docker",
    issuer: "PLURALSIGHT",
    date: "Feb 22, 2024",
    image: docker,
    skills: ["Docker"],
    bgColor: "primary",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
  },
  {
    id: 2,
    title: "OWASP Top 10 2021 Web Application Security Made Simple",
    issuer: "freeCodeCamp",
    date: "January 2024",
    image: OWASP,
    skills: ["JavaScript", "ES6+", "Algorithms", "Data Structures"],
    bgColor: "success",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
  },
  {
    id: 3,
    title: "UI/UX Design Fundamentals",
    issuer: "Google",
    date: "December 2023",
    image: geofit,
    skills: ["Figma", "Design Systems", "User Research", "Prototyping"],
    bgColor: "info",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
  },
  {
    id: 4,
    title: "UI/UX Design Fundamentals",
    issuer: "Google",
    date: "December 2023",
    image: geofit,
    skills: ["Figma", "Design Systems", "User Research", "Prototyping"],
    bgColor: "info",
    url: "https://drive.google.com/file/d/15K6WoRrClUnXzkmBIi8vsyPYssuxJ6FS/view?usp=sharing",
  },
];
